<template>
  <div class="bg-status" :class="orderStatusBackground">
    <div class="mr-1 dot" :class="orderStatusDot" />
    <ion-text class="status-text" :class="orderStatusColor">{{ orderStatus }}</ion-text>
  </div>
</template>
<script>
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    orderStatusDetail: {
      type: Object,
      default: null
    },
    parent: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { t } = useI18n();
    // cek order status for set background status style
    const orderStatusBackground = computed(() => {
      let orderStatus = '';
      if (props.parent === 'orderDetail') {
        const orderLogsInfo = props.orderStatusDetail?.last_order_log_info;
        orderStatus = orderLogsInfo ? orderLogsInfo.status : props.orderStatusDetail?.order_status;
      } else {
        orderStatus = props.orderStatusDetail[0]?.status;
      }

      return ['Completed', 'Delivery Succeed'].includes(orderStatus)
        ? 'bg-green'
        : ['Cancelled', 'Failed Delivery'].includes(orderStatus)
        ? 'bg-red'
        : orderStatus === 'Processing'
        ? 'bg-grey-status'
        : 'bg-orange';
    });

    // cek order status for set dot background status style
    const orderStatusDot = computed(() => {
      let orderStatus = '';
      if (props.parent === 'orderDetail') {
        const orderLogsInfo = props.orderStatusDetail?.last_order_log_info;
        orderStatus = orderLogsInfo ? orderLogsInfo.status : props.orderStatusDetail?.order_status;
      } else {
        orderStatus = props.orderStatusDetail[0]?.status;
      }
      return ['Completed', 'Delivery Succeed'].includes(orderStatus)
        ? 'green'
        : ['Cancelled', 'Failed Delivery'].includes(orderStatus)
        ? 'red'
        : orderStatus === 'Processing'
        ? 'grey'
        : 'orange';
    });

    // cek order status for set status title color
    const orderStatusColor = computed(() => {
      let orderStatus = '';
      if (props.parent === 'orderDetail') {
        const orderLogsInfo = props.orderStatusDetail?.last_order_log_info;
        orderStatus = orderLogsInfo ? orderLogsInfo.status : props.orderStatusDetail?.order_status;
      } else {
        orderStatus = props.orderStatusDetail[0]?.status;
      }
      return ['Completed', 'Delivery Succeed'].includes(orderStatus)
        ? 'text-green'
        : ['Cancelled', 'Failed Delivery'].includes(orderStatus)
        ? 'text-red'
        : orderStatus === 'Processing'
        ? 'text-grey'
        : 'text-orange';
    });

    // set order status title
    const orderStatus = computed(() => {
      let orderStatus = '';
      let isCancelledBySupplier = false;
      if (props.parent === 'orderDetail') {
        const orderLogsInfo = props.orderStatusDetail?.last_order_log_info;
        orderStatus = orderLogsInfo ? orderLogsInfo.status : props.orderStatusDetail?.order_status;
        isCancelledBySupplier = orderLogsInfo.app_name !== 'TreeDots for Merchants';
      } else {
        orderStatus = props.orderStatusDetail[0]?.status;
      }

      return orderStatus === 'Cancelled' && !isCancelledBySupplier
        ? t('order_cancelled')
        : orderStatus === 'Processed'
        ? t('order_processed')
        : orderStatus === 'Processing'
        ? t('order_processing')
        : orderStatus === 'Completed'
        ? t('order_completed')
        : orderStatus === 'Cancelled' && isCancelledBySupplier
        ? t('order_cancel_by_supplier')
        : orderStatus === 'Delivery Started'
        ? t('delivery_started')
        : orderStatus === 'Delivery Succeed'
        ? t('delivered')
        : orderStatus === 'Failed Delivery'
        ? t('failed_delivery')
        : '-';
    });

    return {
      orderStatusBackground,
      orderStatusDot,
      orderStatusColor,
      orderStatus
    };
  }
});
</script>

<style src="../style.scss" lang="scss" scoped></style>
